<template>
  <div class="content" v-if="info">
    <!-- 基本信息 -->
    <SingleInfo :status="2" :info="info"></SingleInfo>
    <!-- 报名表单 -->
    <SingleForm :form="formList ?? info.group" v-if="!params" title="报名人信息" @next="next"></SingleForm>
    <!-- 报名信息 -->
    <EntryInfo v-if="params" :id="orderId" :info="info" title="报名信息" @change="orderChange"></EntryInfo>
    <!-- 支付方式 -->
    <Payment v-if="params && order.state == 1" :orderId="orderId" @success="successPay" @back="back"></Payment>
    <!-- 订单信息 -->
    <OrderInfo v-if="order && order.state == 2" :order="order"></OrderInfo>
    <!-- 倒计时 -->
    <CountDown v-if="params && order.state == 1" :time="time" @end="countEnd"></CountDown>
    <!-- 支付状态弹窗 -->
    <payPopup @close="close"></payPopup>
  </div>
</template>

<script>
import { matchCashier } from '@/service/api/index'
import { activeOrder } from '@/service/api/active'
import { orderCancel } from '@/service/api/user'
import payPopup from '@/components/popup/pay.vue'
import Payment from '@/components/entry/payment.vue'
import OrderInfo from '@/components/entry/orderinfo.vue'
import CountDown from '@/components/entry/countdown.vue'
import EntryInfo from '@/components/entry/entryinfo.vue'
import SingleInfo from '@/components/entry/singleinfo.vue'
import SingleForm from '@/components/entry/singleform.vue'
export default {
  components: { Payment, SingleInfo, EntryInfo, OrderInfo, SingleForm, payPopup, CountDown },
  data() {
    return {
      info: '',
      order: '',
      time: '',
      params: null,
      orderId: this.$route.query.orderId ?? 0,
      formList: JSON.parse(sessionStorage.getItem('formList'))
    }
  },

  mounted() {
    if (!this.$route.query.orderId) {
      // 活动提交表单页面
      this.activeOrder()
    } else {
      // 收银台
      this.matchCashier()
    }
  },

  methods: {
    // 收银台
    async matchCashier() {
      const res = await matchCashier({ id: this.$route.query.orderId })
      this.info = res.msg
      this.params = true
    },
    // 活动提交表单页面
    async activeOrder() {
      const res = await activeOrder({ id: this.$route.query.id })
      this.info = res.msg
    },

    // 支付成功
    successPay(params) {
      this.order = params
    },
    next(obj) {
      this.params = obj.formList
      this.orderId = obj.orderId
      document.documentElement.scrollTop = 0
    },
    back() {
      this.params = null
    },
    // 订单信息
    orderChange(params) {
      this.time = params.time
      this.order = params
    },
    close() {
      this.$store.state.payPopup = false
    },
    async countEnd() {
      const res = await orderCancel({ id: this.orderId })
      if (res.code == 1) return this.$router.go(-1)
      console.log(res)
    }
  }
}
</script>

<style lang="less" scoped></style>
